import '../../components-sass/shared/SuggestedPokemon.scss';
import { PokemonImage } from "./PokemonImage";
import { MergedPokemonData } from "../../interfaces/PokemonGenerationData";

interface SuggestedPokemonProps {
    pokemon: MergedPokemonData;
    onClick: (pokemon: MergedPokemonData) => void;
}

export function SuggestedPokemon({ pokemon, onClick }: SuggestedPokemonProps): JSX.Element {
    return (
        <div className="suggested-pokemon-container" onClick={() => onClick(pokemon)}>
            <PokemonImage name={pokemon.name} imageURL={pokemon.imageURL}/>
            <div className="suggested-pokemon-text">{pokemon.name}</div>
        </div>
    )
}