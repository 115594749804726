import { Action, ActionCreatorWithPayload, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConvertMatchingTypesToSymbols } from "../comparisons/GetAllMatchings";
import { MatchTypesEnum } from "../components/shared/PreviousGuess";
import { Guess } from "../interfaces/GuessesInterfaces";
import { MergedPokemonData, PokemonByGeneration } from "../interfaces/PokemonGenerationData";
import { SubmitGuess } from "../interfaces/SharedReducerInterfaces";
import { GenerationMap } from "./freePlayReducer";
import pokemonData from '../../data/pokemonAPIData.json';

interface DailyReducerData {
    /*
     * all pokemon to guess from, 
     * filtered by generation they first appeared
    */
    pokemonByGeneration: PokemonByGeneration[];
    /*
     * pokemon based on which generations the user enabled
     * and ones that havent been previously guessed
    */ 
    pokemonEligibleToGuess: MergedPokemonData[];
    dailyPokemonToGuess: MergedPokemonData | null;
    hasCorrectGuess: boolean;
    previousGuesses: Guess[];
    previouslyGuessedPokemon: MergedPokemonData[];
    guessSymbols: string[];
    generationsEnabled: GenerationMap;
    isInitalStateLoaded: boolean;
    amountOfCorrectGuesses: number;
    dailyPokemonId: number;
}

const initialState: DailyReducerData = {
    pokemonByGeneration: [],
    pokemonEligibleToGuess: [],
    dailyPokemonToGuess: null,
    hasCorrectGuess: false,
    previousGuesses: [],
    previouslyGuessedPokemon: [],
    guessSymbols: [],
    generationsEnabled: {},
    isInitalStateLoaded: false,
    amountOfCorrectGuesses: 0,
    dailyPokemonId: 0,
};

export const dailySlice = createSlice({
    name: 'daily',
    initialState: initialState,
    reducers: {
        loadInitialState: (state, _action: PayloadAction<undefined>) => {
            const generationsFromData: GenerationMap = {};

            for(const generation of pokemonData){
                if(generation.generation in generationsFromData){
                    continue;
                } 

                generationsFromData[generation.generation] = true;
            }

            const pokemonEligibleToGuess: MergedPokemonData[] = pokemonData.flatMap((data) => data.pokemon as MergedPokemonData[]);

            return {
                pokemonEligibleToGuess: pokemonEligibleToGuess,
                previouslyGuessedPokemon: state.previouslyGuessedPokemon,
                dailyPokemonToGuess: state.dailyPokemonToGuess,
                generationsEnabled: generationsFromData,
                pokemonByGeneration: pokemonData as PokemonByGeneration[],
                guessSymbols: state.guessSymbols,
                hasCorrectGuess: state.hasCorrectGuess,
                previousGuesses: state.previousGuesses,
                isInitalStateLoaded: true,
                amountOfCorrectGuesses: state.amountOfCorrectGuesses,
                dailyPokemonId: state.dailyPokemonId,
            }
        },
        setDailyPokemon: (state, action) => {         
            return {
                ...state,
                dailyPokemonToGuess: action.payload
            }
        },
        submitDailyGuess: (state, action: PayloadAction<SubmitGuess>) => {
            // cannot guess same pokemon twice
            for(const previousGuesses of state.previouslyGuessedPokemon) {
                if(action.payload.pokemonData.name === previousGuesses.name){
                    return {
                        ...state,
                    }
                }
            }

            const removedPokemonFromPool = state.pokemonEligibleToGuess.filter((pokemon: MergedPokemonData) => {
                if(pokemon.name === action.payload.pokemonData.name){
                    return false;
                }

                return true;
            })

            let hasCorrectGuess = true;

            for(const guessField of action.payload.guess.guesses) {
                if(guessField.matchType !== MatchTypesEnum.Full) {
                    hasCorrectGuess = false;
                    break;
                }
            }

            const guessSymbol = ConvertMatchingTypesToSymbols(action.payload.guess);

            return {
                ...state,
                previouslyGuesses: [action.payload.guess, ...state.previousGuesses],
                previouslyGuessedPokemon: [action.payload.pokemonData, ...state.previouslyGuessedPokemon],
                pokemonEligibleToGuess: removedPokemonFromPool,
                hasCorrectGuess: hasCorrectGuess,
                guessSymbols: [guessSymbol, ...state.guessSymbols],
            }
        },
        setAmountOfCorrectGuesses: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                amountOfCorrectGuesses: action.payload,
            }
        },
        setDailyPokemonId: (state, action: PayloadAction<number>) => {
            return {
                ...state,
                dailyPokemonId: action.payload,
            }
        },
    }
})

export const { 
    loadInitialState,
    setDailyPokemon,
    submitDailyGuess,
    setAmountOfCorrectGuesses,
    setDailyPokemonId
} = dailySlice.actions;