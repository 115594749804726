import '../../components-sass/start-screen/ModeSelector.scss'
import { ModeType, selectGameMode } from "../../reducers/modeReducer";
import { store } from "../../store";

export function ModeSelector(): JSX.Element {
    return (
        <div className="mode-selector-container">
            <div className="mode-selector-button" onClick={() => store.dispatch(selectGameMode(ModeType.FreePlay))}>Free Play</div>
            <div className="mode-selector-button" onClick={() => store.dispatch(selectGameMode(ModeType.Daily))}>Daily</div>
        </div>
    )
}