import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConvertMatchingTypesToSymbols } from "../comparisons/GetAllMatchings";
import { MatchTypesEnum } from "../components/shared/PreviousGuess";
import { Guess } from "../interfaces/GuessesInterfaces";
import { MergedPokemonData, PokemonByGeneration } from "../interfaces/PokemonGenerationData";
import { SubmitGuess } from "../interfaces/SharedReducerInterfaces";
import pokemonData from '../../data/pokemonAPIData.json';

export type GenerationMap = Record<string, boolean>;

interface FreePlayReducerData {
    /*
     * all pokemon to guess from, 
     * filtered by generation they first appeared
    */
    pokemonByGeneration: PokemonByGeneration[];
    /*
     * pokemon based on which generations the user enabled
     * and ones that havent been previously guessed
    */ 
    pokemonEligibleToGuess: MergedPokemonData[];
    freePlayPokemonToGuess: MergedPokemonData | null;
    hasCorrectGuess: boolean;
    previousGuesses: Guess[];
    previouslyGuessedPokemon: MergedPokemonData[];
    guessSymbols: string[];
    generationsEnabled: GenerationMap;
    isInitalStateLoaded: boolean;
}

const initialState: FreePlayReducerData = {
    pokemonByGeneration: [],
    pokemonEligibleToGuess: [],
    freePlayPokemonToGuess: null,
    hasCorrectGuess: false,
    previousGuesses: [],
    previouslyGuessedPokemon: [],
    guessSymbols: [],
    generationsEnabled: {},
    isInitalStateLoaded: false,
};

export const freePlaySlice = createSlice({
    name: 'free play',
    initialState: initialState,
    reducers: {
        loadInitialState: (state, _action: PayloadAction<undefined>) => {
            const generationsFromData: GenerationMap = {};

            for(const generation of pokemonData){
                if(generation.generation in generationsFromData){
                    continue;
                } 

                generationsFromData[generation.generation] = true;
            }

            return {
                pokemonEligibleToGuess: state.pokemonEligibleToGuess,
                previouslyGuessedPokemon: state.previouslyGuessedPokemon,
                freePlayPokemonToGuess: state.freePlayPokemonToGuess,
                generationsEnabled: generationsFromData,
                pokemonByGeneration: pokemonData as PokemonByGeneration[],
                guessSymbols: state.guessSymbols,
                hasCorrectGuess: state.hasCorrectGuess,
                previousGuesses: state.previousGuesses,
                isInitalStateLoaded: true,
            }
        },
        startGame: (state, _action: PayloadAction<undefined>) => {
            const allPokemonAvailable: MergedPokemonData[] = [];

            for(const generation of state.pokemonByGeneration){
                if(state.generationsEnabled[generation.generation] === true){
                    for(const pokemon of generation.pokemon){
                        allPokemonAvailable.push(pokemon);
                    }
                }
            }

            const pokemonToGuess = allPokemonAvailable[Math.floor(Math.random() * allPokemonAvailable.length)];

            return {
                ...state,
                freePlayPokemonToGuess: pokemonToGuess,
                pokemonEligibleToGuess: allPokemonAvailable,
            }
        },
        setFreePlayPokemon: (state, action) => {         
            return {
                ...state,
                freePlayPokemon: action.payload
            }
        },
        submitFreePlayGuess: (state, action: PayloadAction<SubmitGuess>) => {
            // cannot guess same pokemon twice
            for(const previousGuesses of state.previouslyGuessedPokemon) {
                if(action.payload.pokemonData.name === previousGuesses.name){
                    return {
                        ...state,
                    }
                }
            }

            const removedPokemonFromPool = state.pokemonEligibleToGuess.filter((pokemon: MergedPokemonData) => {
                if(pokemon.name === action.payload.pokemonData.name){
                    return false;
                }

                return true;
            })

            let hasCorrectGuess = true;

            for(const guessField of action.payload.guess.guesses) {
                if(guessField.matchType !== MatchTypesEnum.Full) {
                    hasCorrectGuess = false;
                    break;
                }
            }

            const guessSymbol = ConvertMatchingTypesToSymbols(action.payload.guess);

            return {
                ...state,
                previouslyGuesses: [action.payload.guess, ...state.previousGuesses],
                previouslyGuessedPokemon: [action.payload.pokemonData, ...state.previouslyGuessedPokemon],
                pokemonEligibleToGuess: removedPokemonFromPool,
                hasCorrectGuess: hasCorrectGuess,
                guessSymbols: [guessSymbol, ...state.guessSymbols],
            }
        },
        toggleGenerationEnabled: (state, action: PayloadAction<string>) => {
            const newState = state.generationsEnabled;
            newState[action.payload] = !newState[action.payload];
        },
        resetGameState: (state, _action: PayloadAction<undefined>) => {
            return {
                ...state,
                freePlayPokemonToGuess: initialState.freePlayPokemonToGuess,
                pokemonEligibleToGuess: initialState.pokemonEligibleToGuess,
                previouslyGuessedPokemon: initialState.previouslyGuessedPokemon,
                guessSymbols: initialState.guessSymbols,
                hasCorrectGuess: initialState.hasCorrectGuess,
                previousGuesses: initialState.previousGuesses,
            };
        },
    },
})

export const { 
    loadInitialState, 
    resetGameState,
    startGame,
    setFreePlayPokemon,
    submitFreePlayGuess,
    toggleGenerationEnabled,
} = freePlaySlice.actions;







