import '../../components-sass/shared/PokedleContainer.scss'
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ModeSelector } from ".././start-screen/ModeSelector";
import { ModeType } from "../../reducers/modeReducer";
import { FreePlay } from ".././free-play/FreePlay";
import { PokedleLogo } from "./PokedleLogo";
import { DailyGuess } from '../daily/DailyGuess';
import PacmanLoader from 'react-spinners/PacmanLoader';


const ModeTypeToComponentMapping: Record<ModeType, JSX.Element> = {
    [ModeType.NoneSelected]: <ModeSelector/>,
    [ModeType.FreePlay]: <FreePlay/>,
    [ModeType.Daily]: <DailyGuess/>,
}

export function PokedleContainer(): JSX.Element {
    const { loadingDaily, loadingFreePlay } = useSelector((root: RootState) => {
        return {
          loadingDaily: !root.daily.pokemonByGeneration.length,
          loadingFreePlay:!root.freeplay.pokemonByGeneration.length,
        }
      })

    const currentModeType = useSelector((state: RootState) => state.modes.currentMode);

    return (
        <div className="pokedle-container-main">
            <PokedleLogo/> 
            {(loadingDaily || loadingFreePlay) ? 
            <PacmanLoader 
                loading={loadingDaily || loadingFreePlay}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                color={'yellow'}
            /> :
                <>
                   {ModeTypeToComponentMapping[currentModeType]}
                </>
            }
        </div>
    )
}