import { MatchTypeComponentWithArrowProps, Arrow, MatchTypesEnum } from "../components/shared/PreviousGuess";

export function DoesMatch(pokemonStat: number, guessStat: number): MatchTypeComponentWithArrowProps {    
    if(pokemonStat > guessStat){
        return {
            arrowType: Arrow.Up,
            matchType: MatchTypesEnum.None,
            statToDisplay: [guessStat],
        }
    }

    if(pokemonStat < guessStat){
        return {
            arrowType: Arrow.Down,
            matchType: MatchTypesEnum.None,
            statToDisplay: [guessStat],
        }
    }

    return {
        arrowType: Arrow.None,
        matchType: MatchTypesEnum.Full,
        statToDisplay: [guessStat],
    }
}