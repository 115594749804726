import '../../components-sass/shared/GuessBox.scss';
import * as React from "react";
import { RootState, store } from "../../store";
import { SuggestedPokemon } from "./SuggestedPokemon";
import { MergedPokemonData } from "../../interfaces/PokemonGenerationData";
import { SubmitGuess as SubmitGuessInterface } from "../../interfaces/SharedReducerInterfaces";
import { GetAllMatchings } from "../../comparisons/GetAllMatchings";
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { MatchTypesEnum } from './PreviousGuess';
import { useSelector } from 'react-redux';
import { ModeType } from '../../reducers/modeReducer';
import { setAmountOfCorrectGuesses } from '../../reducers/dailyReducer';

interface GuessBoxProps {
    hasCorrectGuess: boolean;
    pokemonToGuess: MergedPokemonData,
    pokemonEligibleToGuess: MergedPokemonData[],
    submitGuessAction: ActionCreatorWithPayload<SubmitGuessInterface, string>,
}

export function GuessBox({
    hasCorrectGuess,
    pokemonToGuess,
    pokemonEligibleToGuess,
    submitGuessAction,
}: GuessBoxProps): JSX.Element {
    const currentMode = useSelector((root: RootState) => root.modes.currentMode);
    const [currentGuessString, setCurrentGuessString] = React.useState('');
    const [alternativeOptions, setAlternativeOptions] = React.useState<MergedPokemonData[]>([]);

    React.useEffect(() => {
        if(currentGuessString.length ===0) {
            setAlternativeOptions([]);
            return;
        }

        const filteredDownPokemon = pokemonEligibleToGuess.filter((pokemon: MergedPokemonData) => {
            if(pokemon.name.toLowerCase()[0] === currentGuessString.toLowerCase()[0] 
                && pokemon.name.toLowerCase().includes(currentGuessString.toLowerCase())){
                return true;
            }

            return false;
        })

        setAlternativeOptions(filteredDownPokemon);
    }, [currentGuessString, pokemonEligibleToGuess])

    if(hasCorrectGuess) {
        return <></>;
    }

    return (
        <div className="width-placeholder">
            <div className="guess-box-container">
                <input 
                    placeholder='Type pokemon name ...'
                    type='text'
                    onChange={(event) => setCurrentGuessString(event.target.value)}
                    onKeyDown={(event) => handleGuessSubmition(
                        event, 
                        currentGuessString, 
                        setCurrentGuessString, 
                        submitGuessAction,
                        pokemonEligibleToGuess,
                        pokemonToGuess,
                        currentMode,
                    )}
                    value={currentGuessString}
                />
                <div className="alternatives-container">
                    {
                        alternativeOptions.map((pokemon: MergedPokemonData) => 
                            <SuggestedPokemon 
                                key={pokemon.name} 
                                pokemon={pokemon} 
                                onClick={() => SubmitGuess(
                                    pokemon.name, 
                                    setCurrentGuessString, 
                                    submitGuessAction,
                                    pokemonEligibleToGuess,
                                    pokemonToGuess,
                                    currentMode,
                                )}
                            />
                        )
                    }
                </div>  
            </div>
        </div>
    )
}

function handleGuessSubmition(
    event: React.KeyboardEvent<HTMLInputElement>, 
    currentGuessString: string, 
    setCurrentGuessString: (newString: string) => void,
    submitGuessAction: ActionCreatorWithPayload<SubmitGuessInterface, string>,
    pokemonEligibleToGuess: MergedPokemonData[],
    pokemonToGuess: MergedPokemonData,
    currentMode: ModeType,
) {
    if(event.key === 'Enter'){
        SubmitGuess(
            currentGuessString, 
            setCurrentGuessString,
            submitGuessAction, 
            pokemonEligibleToGuess, 
            pokemonToGuess,
            currentMode
        );
    }
}

function SubmitGuess(
    currentGuessString: string,
    setCurrentGuessString: (newString: string) => void,
    submitGuessAction: ActionCreatorWithPayload<SubmitGuessInterface, string>,
    pokemonEligibleToGuess: MergedPokemonData[],
    pokemonToGuess: MergedPokemonData,
    currentMode: ModeType,
) {
    const pokemonChosen = pokemonEligibleToGuess.find((pokedata) => pokedata.name.toLowerCase() === currentGuessString.toLowerCase());

    if(pokemonChosen){
        let hasCorrectGuess = true;

        for(const guessField of GetAllMatchings(pokemonChosen, pokemonToGuess).guesses) {
            if(guessField.matchType !== MatchTypesEnum.Full) {
                hasCorrectGuess = false;
                break;
            }
        }

        if(hasCorrectGuess && currentMode === ModeType.Daily){
            SubmitCorrectGuess();
        }

        store.dispatch(submitGuessAction({
            pokemonData: pokemonChosen,
            guess: GetAllMatchings(pokemonChosen, pokemonToGuess)
        }));
        setCurrentGuessString('');
    }
}

async function SubmitCorrectGuess() {
    await fetch('https://pokedle-feeder.vercel.app/updateCorrectGuessAmount', { mode: 'cors',});

    const data = await fetch('https://pokedle-feeder.vercel.app/getCorrectGuessesAmount', { mode: 'cors',});
    const dataJSON = await data.json();

    store.dispatch(setAmountOfCorrectGuesses(dataJSON.amountOfCorrectGuesses));
}