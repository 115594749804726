import { useSelector } from "react-redux";
import { submitFreePlayGuess, resetGameState } from "../../reducers/freePlayReducer";
import { RootState, store } from "../../store";
import { ClassicGameMode } from "../shared/ClassicGameMode";
import { FreePlayStartScreen } from "./FreePlayStartScreen";

export function FreePlay(): JSX.Element {
    const pokemonToGuess = useSelector((state:RootState) => state.freeplay.freePlayPokemonToGuess);
    const previousGuesses = useSelector((state: RootState) => state.freeplay.previouslyGuessedPokemon);
    const hasCorrectGuess = useSelector((state: RootState) => state.freeplay.hasCorrectGuess);
    const symbols = useSelector((state: RootState) => state.freeplay.guessSymbols);
    const pokemonEligibleToGuess = useSelector((state: RootState) => state.freeplay.pokemonEligibleToGuess);
    
    return (
        <>
            {!pokemonToGuess 
                ? <FreePlayStartScreen/> 
                : <ClassicGameMode
                        pokemonToGuess={pokemonToGuess}
                        eligablePokemon={pokemonEligibleToGuess}
                        submitGuessAction={submitFreePlayGuess}
                        previousGuesses={previousGuesses}
                        amountOfAttempts={previousGuesses.length}
                        guessSymbols={symbols}
                        hasCorrectGuess={hasCorrectGuess}
                        resetGameAction={resetGameState}
                />
            }  
        </> 
    )
}