import { MatchType, MatchTypesEnum } from "../components/shared/PreviousGuess";

export function DoesTypeMatch(
    guessType1: string, 
    currentType1: string, 
    guessType2: string | null,
    currentType2: string | null,
): MatchType {
    let doesType1Match = false;
    let doesType2Match = false;

    if(guessType1 === currentType1 || guessType1 === currentType2) {
        doesType1Match = true;

        if(guessType2 === null && currentType2 === null) {
            return MatchTypesEnum.Full;
        }
    }

    if(guessType2 !== null) {
        if(guessType2 === currentType2 || guessType2 === currentType1) {
            doesType2Match = true;
        }
    }

    if(doesType1Match && doesType2Match) {
        return MatchTypesEnum.Full;
    }

    if(doesType1Match || doesType2Match) {
        return MatchTypesEnum.Half;
    }

    return MatchTypesEnum.None;
}

