import { ActionCreatorWithoutPayload, ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { MergedPokemonData } from "../../interfaces/PokemonGenerationData";
import { SubmitGuess } from "../../interfaces/SharedReducerInterfaces";
import { CorrectGuess } from "../correct-guess/CorrectGuess";
import { GuessBox } from "./GuessBox";
import { PreviousGuesses } from "./PreviousGuess";

interface ClassicGameModeProps {
    submitGuessAction: ActionCreatorWithPayload<SubmitGuess, string>,
    eligablePokemon: MergedPokemonData[],
    pokemonToGuess: MergedPokemonData,
    previousGuesses: MergedPokemonData[],
    amountOfAttempts: number,
    guessSymbols: string[],
    hasCorrectGuess: boolean,
    resetGameAction?: ActionCreatorWithoutPayload,
}

export function ClassicGameMode({
    submitGuessAction,
    eligablePokemon,
    pokemonToGuess, 
    previousGuesses,
    amountOfAttempts,
    guessSymbols,
    hasCorrectGuess,
    resetGameAction,
}: ClassicGameModeProps): JSX.Element {
    return (
        <>
            <GuessBox 
                hasCorrectGuess={hasCorrectGuess}
                pokemonToGuess={pokemonToGuess}
                pokemonEligibleToGuess={eligablePokemon} 
                submitGuessAction={submitGuessAction}
            />
            <PreviousGuesses
                pokemonToGuess={pokemonToGuess}
                previousGuesses={previousGuesses}
            />
            <CorrectGuess
                amountOfAttempts={amountOfAttempts}
                guessSymbols={guessSymbols}
                hasCorrectGuess={hasCorrectGuess}
                resetGameAction={resetGameAction}
            /> 
        </> 
    )

}