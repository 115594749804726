import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { dailySlice } from './reducers/dailyReducer';
import { freePlaySlice } from './reducers/freePlayReducer';
import { modeSlice } from './reducers/modeReducer';

export const store = configureStore({
  reducer: {
    modes: modeSlice.reducer,
    daily: dailySlice.reducer,
    freeplay: freePlaySlice.reducer,
  },

  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
