import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import React from "react";
import { useSelector } from "react-redux";
import { setAmountOfCorrectGuesses } from "../../reducers/dailyReducer";
import { ModeType } from "../../reducers/modeReducer";
import { RootState, store } from "../../store";
import { GuessSymbols } from "../correct-guess/GuessSymbols";
import { Countdown } from "../daily/Countdown";

interface VictoryPopupProps {
    amountOfAttempts: number;
    guessSymbols: string[];
    resetGameAction?: ActionCreatorWithoutPayload,
}

const generationText = (attemps: number) => `Congratulations you guessed the correct pokemon in only ${attemps} attempts`;
const VictoryText = (attemps: number, pokeId: number) => `I found #Pokedle pokemon #${pokeId} in only ${attemps} attempts`;
const websiteAddress = 'https://www.pokedle.club/';

export function VictoryPopup({
    amountOfAttempts,
    guessSymbols,
    resetGameAction
}: VictoryPopupProps): JSX.Element {
    const pokemonId = useSelector((root: RootState) => root.daily.dailyPokemonId);
    const amountOfCorrectGuesses = useSelector((root: RootState) => root.daily.amountOfCorrectGuesses);
    const currentMode = useSelector((root: RootState) => root.modes.currentMode);

    React.useEffect(() => {
        document.getElementById('victory-popup')!.scrollIntoView({ behavior: 'smooth'});
    }, [currentMode])

    return (
        <div className='victory-popup' id='victory-popup'>
            <p className='congratulations-text'>{generationText(amountOfAttempts)}</p>
            <GuessSymbols 
                symbols={guessSymbols}
                maxSymbols={6}
            />
            <div className="website-address-text"></div>
            { currentMode === ModeType.Daily && 
                <>
                    <div>{amountOfCorrectGuesses} people have already guessed the correct answer</div>
                    <Countdown/>
                    <img 
                        src="misc/copyIcon.webp"
                        alt="Copy"
                        onClick={() => CopyVictoryText(
                            VictoryText(amountOfAttempts, pokemonId),
                            guessSymbols,
                            websiteAddress,
                            6
                    )}/>
                </>
            }
            { resetGameAction && <button onClick={() => store.dispatch(resetGameAction())}>Play Again</button>}
        </div>
    )
}

function CopyVictoryText(text: string, symbols: string[], websiteLink: string, maxSymbols: number): void {
    const drawableSymbols = symbols.length > maxSymbols ? symbols.slice(0, maxSymbols) : symbols;
    const extraGuessesString = symbols.length - maxSymbols > 0 ? `+${symbols.length - maxSymbols}\n` : '';

    let symbolsString: string = '';

    for(const symbol of drawableSymbols) {
        symbolsString += symbol;
        symbolsString += '\n';
    }

    const fullString = text.concat('\n').concat(symbolsString).concat(extraGuessesString).concat(websiteLink);

    navigator.clipboard.writeText(fullString)
}