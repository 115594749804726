import '../../components-sass/daily/Countdown.scss';
import React from "react"

export function Countdown():JSX.Element {
    const [hour, setHour] = React.useState(0);
    const [minute, setMinute] = React.useState(0);
    const [second, setSecond] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isNewPokemonReady, setIsPokemonReady] = React.useState(false);
    
    React.useEffect(() => {
        function CountDown(){
            const currentdate= new Date();
            const tomorrow = new Date();
        
            tomorrow.setDate(currentdate.getDate() + 1);
            tomorrow.setHours(0, 0, 0, 0);
            
            const timeTill = tomorrow.getTime() - currentdate.getTime();

            if(timeTill < 0) {
                setIsPokemonReady(true);
            }
    
            setHour(Math.floor((timeTill % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            setMinute(Math.floor((timeTill % (1000 * 60 * 60)) / (1000 * 60)));
            setSecond(Math.floor((timeTill % (1000 * 60)) / 1000));

            setIsLoading(false);
        }

        setInterval(() => {
            CountDown();
        }, 1000)
    }, [])


    return (
        <div className="countdown-container">
            Next Pokemon In
            { !isLoading && !isNewPokemonReady && <div className="countdown-text">{ `${hour}:${minute}:${second}` }</div>}
            { isNewPokemonReady && <div>NOW! Refresh For New Daily Guess</div>}
        </div>
    )
}