import { useSelector } from "react-redux/es/hooks/useSelector";
import { toggleGenerationEnabled } from "../../reducers/freePlayReducer";
import { RootState } from "../../store";
import { EnabledGenerations } from "./FreePlayEnabledGenerations";

export function FreePlayStartScreen(): JSX.Element {
    const enabledGenerations = useSelector((state: RootState) => state.freeplay.generationsEnabled);
    const isStateLoaded = useSelector((state: RootState) => state.freeplay.isInitalStateLoaded);

    return (
        <div className="start-screen-container">
            <EnabledGenerations
                enabledGenerations={enabledGenerations}
                isStateLoaded={isStateLoaded}
                toggleGenerationAction={toggleGenerationEnabled}
            />
        </div>
    )
}