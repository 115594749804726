import '../../components-sass/shared/PreviousGuess.scss';
import { PokemonImageWithHover } from "./PokemonImage";
import { DoesTypeMatch } from "../../comparisons/TypeMatching";
import { DoesMatch } from '../../comparisons/BaseMatching';
import { MergedPokemonData } from '../../interfaces/PokemonGenerationData';

interface MatchTypeComponentProps {
    children?: React.ReactNode;
    matchType: MatchType;
    statToDisplay: string[] | number[] | undefined[];
}

export interface MatchTypeComponentWithArrowProps extends MatchTypeComponentProps {
    arrowType: ArrowType;
};

export enum Arrow {
    Up = 'Up',
    Down = 'Down',
    None = 'None',
}

export enum MatchTypesEnum {
    Full = 'Full',
    Half = 'Half',
    None = 'None',
}

export type ArrowType = Arrow.Down | Arrow.Up | Arrow.None;
export type MatchType = MatchTypesEnum.Full | MatchTypesEnum.Half | MatchTypesEnum.None;

interface PreviousGuessesProps {
    previousGuesses: MergedPokemonData[],
    pokemonToGuess: MergedPokemonData,
}

export function PreviousGuesses({
    pokemonToGuess,
    previousGuesses,
}: PreviousGuessesProps): JSX.Element {
    if(previousGuesses.length === 0) {
        return <></>
    }

    return (
        <div className='previous-guess-table'>
        <table>
            <tbody>
            { previousGuesses.length !== 0 && 
                <tr className='headings'>
                    <th>Pokemon</th>
                    <th>Generation</th>
                    <th>Health</th>
                    <th>Attack</th>
                    <th>Defence</th>
                    <th>Sp. Attack</th>
                    <th>Sp. Defence</th>
                    <th>Speed</th>
                    <th>Types</th>
                </tr>
            }
               {previousGuesses.map((guess) => {
                    return (    
                        <tr className='guess-row' key={guess.name}>
                            <td><PokemonImageWithHover name={guess.name} imageURL={guess.imageURL} hover={true}/></td>
                            <td><MatchTypeComponentWithArrow props={DoesMatch(pokemonToGuess!.generationFrom, guess!.generationFrom)} /></td>
                            <td><MatchTypeComponentWithArrow props={DoesMatch(pokemonToGuess!.stats.hp, guess.stats.hp)}/></td>
                            <td><MatchTypeComponentWithArrow props={DoesMatch(pokemonToGuess!.stats.attack, guess.stats.attack)}/></td>
                            <td><MatchTypeComponentWithArrow props={DoesMatch(pokemonToGuess!.stats.defence, guess.stats.defence)}/></td>
                            <td><MatchTypeComponentWithArrow props={DoesMatch(pokemonToGuess!.stats.specialAttack, guess.stats.specialAttack)}/></td>
                            <td><MatchTypeComponentWithArrow props={DoesMatch(pokemonToGuess!.stats.specialDefence, guess.stats.specialDefence)}/></td>
                            <td><MatchTypeComponentWithArrow props={DoesMatch(pokemonToGuess!.stats.speed, guess.stats.speed)}/></td>
                            <td>
                                <MatchTypeComponent matchType={
                                    DoesTypeMatch(
                                        guess?.types[0], 
                                        pokemonToGuess!.types[0], 
                                        guess?.types[1], 
                                        pokemonToGuess!.types[1],
                                        )
                                    }
                                    statToDisplay={[guess?.types[0], (guess?.types[0] !== guess?.types[1]) ? guess?.types[1] : '']}
                                />
                            </td>
                        </tr>
                    )}
                )}
            </tbody>
        </table>
        </div>
    )
}

function MatchTypeComponent({ matchType, children, statToDisplay}: MatchTypeComponentProps ): JSX.Element {
    return (
        <div className={`match-type-${matchType.toLowerCase()}`}>
            <div className='guess-stat-text'>{statToDisplay.map((stat: string | number | undefined) => <div key={stat}>{stat !== undefined ? stat : ''}</div>)}</div>
            {children}
        </div>
    )
}

function MatchTypeComponentWithArrow({props}: {props: MatchTypeComponentWithArrowProps}) {
    return (
        <>
            <MatchTypeComponent matchType={props.matchType} statToDisplay={props.statToDisplay}>
                <img className={`arrow-${props.arrowType}`} src='misc/arrow.png' alt='arrow'/>
            </MatchTypeComponent>
        </>    
    )
}
