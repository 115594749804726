import { Arrow, MatchTypeComponentWithArrowProps, MatchTypesEnum } from "../components/shared/PreviousGuess";
import { Guess } from "../interfaces/GuessesInterfaces";
import { MergedPokemonData } from "../interfaces/PokemonGenerationData";
import { DoesMatch } from "./BaseMatching";
import { DoesTypeMatch } from "./TypeMatching";

// Unicode Emoji's
const green = '🟩';
const red = '🟥';
const orange = '🟧';
const down = '⬇️';
const up = '⬆️';

export function GetAllMatchings(pokemonGuessed: MergedPokemonData, pokemonToGuess: MergedPokemonData): Guess {
    const matchinTypes: MatchTypeComponentWithArrowProps[] = [];
    
    matchinTypes.push(DoesMatch(pokemonToGuess!.generationFrom, pokemonGuessed.generationFrom));
    matchinTypes.push(DoesMatch(pokemonToGuess!.stats.hp, pokemonGuessed.stats.hp));
    matchinTypes.push(DoesMatch(pokemonToGuess!.stats.attack, pokemonGuessed.stats.attack));
    matchinTypes.push(DoesMatch(pokemonToGuess!.stats.defence, pokemonGuessed.stats.defence));
    matchinTypes.push(DoesMatch(pokemonToGuess!.stats.specialAttack, pokemonGuessed.stats.specialAttack));
    matchinTypes.push(DoesMatch(pokemonToGuess!.stats.specialDefence, pokemonGuessed.stats.specialDefence));
    matchinTypes.push(DoesMatch(pokemonToGuess!.stats.speed, pokemonGuessed.stats.speed));

    matchinTypes.push({
        matchType: DoesTypeMatch(pokemonGuessed?.types[0], pokemonToGuess!.types[0], pokemonGuessed?.types[1], pokemonToGuess!.types[1]),
        arrowType: Arrow.None,
        statToDisplay: [pokemonGuessed?.types[0], (pokemonGuessed?.types[0] !== pokemonGuessed?.types[1]) ? pokemonGuessed?.types[1] : '']
    });

    return {
        guesses: matchinTypes
    }
}

export function ConvertMatchingTypesToSymbols(guess: Guess): string {
    let string = ''

    for(const guessData of guess.guesses){
        if(guessData.matchType === MatchTypesEnum.Full){
            string += green;
        }
        
        else if(guessData.matchType === MatchTypesEnum.Half){
            string += orange;
        }

        else if(guessData.matchType === MatchTypesEnum.None && guessData.arrowType === Arrow.None){
            string += red;
        }
        
        else if(guessData.arrowType === Arrow.Down) {
            string += down;
        }

        else if(guessData.arrowType === Arrow.Up) {
            string += up;
        }
    }

    return string;
}
