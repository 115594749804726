import '../../components-sass/shared/PokemonImage.scss';
import React from "react";


interface PokemonImageProps {
    name: string;
    imageURL: string;
    hover?: boolean;
}

/**
 * used for suggesting pokemon to guess 
 */
export function PokemonImage({ name, imageURL }: PokemonImageProps): JSX.Element {    
    return (
        <div className='image-container'>
            <img src={imageURL} alt={name}/>
        </div>
    )
}

/**
 * Used for previous guesses
 */
export function PokemonImageWithHover({ name, hover, imageURL }: PokemonImageProps): JSX.Element {
    const [isHovered, setIsHovered] = React.useState(false);
    
    return (
        <div className='image-container-with-hover'>
            <img src={imageURL} alt={name} onMouseOver={() => setIsHovered(true)}  onMouseOut={() => setIsHovered(false)} />
            { hover && isHovered && <div className="image-hover-text">{name}</div>}
        </div>
    )
}