import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetGameStateAction } from "./sharedActions";

export enum ModeType {
    NoneSelected = 'NoneSelected',
    FreePlay = 'Freeplay',
    Daily = 'Daily',
}

const initialState: modeData = {
    currentMode: ModeType.NoneSelected
}

interface modeData {
    currentMode: ModeType;
} 

export const modeSlice = createSlice({
    name: 'mode',
    initialState: initialState,
    reducers: {
        selectGameMode: (state, action: PayloadAction<ModeType>) => {
            return {
                ...state,
                currentMode: action.payload,
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(resetGameStateAction, (state, _action) => {
            return {
                ...state,
                currentMode: ModeType.NoneSelected,
            };
        })
    },
})

export const { selectGameMode } = modeSlice.actions; 
