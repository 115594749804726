interface GuessSymbolsProps {
    symbols: string[],
    maxSymbols: number,
}

export function GuessSymbols({
    symbols,
    maxSymbols,
}: GuessSymbolsProps): JSX.Element {
    const drawableSymbols = symbols.length > maxSymbols ? symbols.slice(0, maxSymbols) : symbols;
    const extraGuesses = symbols.length - maxSymbols;

    return (
        <div className="symbols-div">
            { drawableSymbols.map((symbolsRow: string, index: number) => <p className="symbol-row" key={index}>{symbolsRow}</p> ) }
            { extraGuesses > 0  && <div>+ {extraGuesses}</div> }
        </div>
    )
}