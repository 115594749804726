import './App.scss';
import React from 'react';
import { PokedleContainer } from './app/components/shared/PokedleContainer';
import { loadInitialState as loadInitialStateFreePlay } from './app/reducers/freePlayReducer';
import { loadInitialState as loadInitialStateDaily } from './app/reducers/dailyReducer';
import { PokemonDataLoader } from './app/loaders/PokemonDataLoader';
import { store } from './app/store';
import { inject } from '@vercel/analytics';
import { getDailyPokemon } from './app/loaders/DailyPokemonLoader';

function App() {
  React.useEffect(() => {
    inject();

    store.dispatch(loadInitialStateFreePlay());
    store.dispatch(loadInitialStateDaily());
    
    getDailyPokemon();

    updateJSONFile(false);
  }, [])

  return (
    <div className="App">
        <PokedleContainer/>    
    </div>
  );
}

function updateJSONFile(shouldUpdate: boolean): void {
  if(shouldUpdate){
    PokemonDataLoader();
  }
}

export default App;