import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import '../../components-sass/correct-guess/CorrectGuess.scss';
import { VictoryPopup } from '../shared/VictoryPopup';

interface CorrectGuessProps {
    hasCorrectGuess: boolean;
    amountOfAttempts: number;
    guessSymbols: string[];
    resetGameAction?: ActionCreatorWithoutPayload,
}

export function CorrectGuess({ 
    hasCorrectGuess,
    amountOfAttempts,
    guessSymbols,
    resetGameAction
}: CorrectGuessProps): JSX.Element {
    return (
        <div className="correct-guess-width">
            {
                hasCorrectGuess && 
                    <VictoryPopup
                        amountOfAttempts={amountOfAttempts}
                        guessSymbols={guessSymbols}
                        resetGameAction={resetGameAction}
                     />
            }
        </div>
    )    
}





