import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ClassicGameMode } from "../shared/ClassicGameMode";
import { submitDailyGuess } from "../../reducers/dailyReducer";
import { getDailyPokemon } from "../../loaders/DailyPokemonLoader";
import PacmanLoader from 'react-spinners/PacmanLoader';
import React from "react";

export function DailyGuess(): JSX.Element {
    const pokemonToGuess = useSelector((state: RootState) => state.daily.dailyPokemonToGuess);
    const guessSymbols = useSelector((state: RootState) => state.daily.guessSymbols);
    const hasCorrectGuess = useSelector((state: RootState) => state.daily.hasCorrectGuess);
    const previousGuesses = useSelector((state: RootState) => state.daily.previouslyGuessedPokemon);
    const pokemonEligibleToGuess = useSelector((state: RootState) => state.daily.pokemonEligibleToGuess);

    if (!pokemonToGuess) {
        return <PacmanLoader
            loading={true}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            color={'yellow'}
        />
    }

    return <ClassicGameMode
        pokemonToGuess={pokemonToGuess}
        eligablePokemon={pokemonEligibleToGuess}
        submitGuessAction={submitDailyGuess}
        previousGuesses={previousGuesses}
        amountOfAttempts={previousGuesses.length}
        guessSymbols={guessSymbols}
        hasCorrectGuess={hasCorrectGuess}
    />
}