import crypto from 'crypto-js';
import { setAmountOfCorrectGuesses, setDailyPokemon, setDailyPokemonId } from '../reducers/dailyReducer';
import { store } from '../store';

export async function getDailyPokemon() {
  const encryptedDataResponse = await fetch('https://pokedle-feeder.vercel.app/getDailyPokemon', {
    mode: 'cors',
  });

  const encryptedData = await encryptedDataResponse.json();

  const bytes = crypto.AES.decrypt(encryptedData.pokemon, `${process.env.REACT_APP_SUPER_SECRET_KEY_DO_NOT_STEAL_PLEASE}`);
  const decryptedPokeSting = bytes.toString(crypto.enc.Utf8);

  let pokemonData = null;

  for (const generation of store.getState().freeplay.pokemonByGeneration) {
    for (const pokemon of generation.pokemon) {
      if (pokemon.name === decryptedPokeSting) {
        pokemonData = pokemon;
      }
    }
  }

  if (pokemonData === null) {
    console.error(`no pokemon found for pokedata of ${pokemonData}`);
  }

  store.dispatch(setDailyPokemon(pokemonData));
  store.dispatch(setDailyPokemonId(encryptedData._id));
  store.dispatch(setAmountOfCorrectGuesses(encryptedData.amountOfCorrectGuesses));
}
