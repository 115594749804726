import '../../components-sass/free-play/EnabledGenerations.scss';
import { startGame, GenerationMap } from "../../reducers/freePlayReducer";
import { store } from "../../store"
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

interface EnabledGenerationsProps {
    enabledGenerations: GenerationMap;
    isStateLoaded: boolean;
    toggleGenerationAction: ActionCreatorWithPayload<string>,
}

export function EnabledGenerations({
    enabledGenerations,
    isStateLoaded,
    toggleGenerationAction,
}: EnabledGenerationsProps): JSX.Element {
    return (
        <div className="enabled-generations-container">
            <div>Generations Enabled</div>
            <p>enable generations based on what pokemon you want available to guess from</p>
            {isStateLoaded ? 
                <>
                    <div className='generation-toggles'>
                        {Object.entries(enabledGenerations).map((entry) => {
                            return (
                                <button 
                                    className={`generation-box-${entry[1]}`}
                                    key={entry[0]} 
                                    onClick={() => store.dispatch(toggleGenerationAction(entry[0]))} 
                                >
                                    {entry[0]}
                                </button>
                            )
                        })}
                    </div>
                    <button className="start-game-button" onClick={() => store.dispatch(startGame())}>Start Game</button>
                </>
                :  
                <div>loading pokemon data...</div>
            }
        </div>
    )
}
